import React from 'react'
import Layout from '../components/Layout'
import { connect } from 'react-redux'
const NotFoundPage = (IsHungarian) => (
  
    <Layout>
        <div>
            <h1>{IsHungarian ? "Az oldal nem található" : "Seite nicht gefunden"}</h1>
            
        </div>
    </Layout>
)


export default connect(
    state => ({
        IsHungarian: state.app.IsHungarian,
    }),
    null
)(NotFoundPage)